import React from 'react'
import { List, TextField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

export default props => (
  <List actions={null} bulkActions={false} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="name" />
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
    </UMDatagrid>
  </List>
)
