import React from 'react'
import get from 'lodash/get'
import { FunctionField } from 'react-admin'

const UMFullNameField = ({ firstNameKey = 'firstName', lastNameKey = 'lastName', ...props }) => (
  <FunctionField
    label="Name"
    render={record => `${get(record, firstNameKey)} ${get(record, lastNameKey)}`}
    {...props}
  />
)

UMFullNameField.defaultProps = {
  addLabel: true,
}

export default UMFullNameField
