import React from 'react'
import { Edit, SimpleForm, TextInput, DateInput, NumberInput, ReferenceInput, SelectInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import expenses from '../../api/resources/expenses'

const ExpenseEdit = ({ parentRecord, dispatch, ...props }) => {
  return (
    <Edit title={<UMBreadcrumbs resourceName={expenses.getName()} currentLabel="Edit expense" />} {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <SelectInput
          source="type"
          defaultValue="PRIVATE"
          choices={[{ id: 'PRIVATE', name: 'Private' }, { id: 'PRO', name: 'Pro' }]}
        />
        <ReferenceInput label="Category" source="category.id" reference="expenseCategories">
          <SelectInput source="categoryId" optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>

        <ReferenceInput label="Provider" source="provider.id" reference="providers">
          <SelectInput optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>

        <NumberInput source="amount" defaultValue={0} label="Amount (in cents)" />
        <DateInput source="startedOn" defaultValue={new Date()} />
        <DateInput source="endedOn" defaultValue={new Date()} />
        <DateInput source="expensedOn" defaultValue={new Date()} />
      </SimpleForm>
    </Edit>
  )
}

export default ExpenseEdit
