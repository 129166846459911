import get from 'lodash/get'
import { formatDate } from '../../utils/dates'

const fieldsPartial = `
  id
  total
  used
  remaining
  startsOn
  endsOn
  membership {
    id
  }
`

const filterTypes = {
  ids: '[ID]',
  membershipId: 'ID',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getParentResourceName = () => 'memberships'

const getParentResourceIdKey = () => 'membership.id'

const getName = () => 'budgets'

const getLabel = () => 'Budgets'

const getRecordById = (state, id) => get(state, `admin.resources.budgets.data.${id}`, null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${formatDate(record.startsOn)} — ${formatDate(record.endsOn)}`
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResourceName,
  getParentResourceIdKey,
  getName,
  getLabel,
  getRecordById,
  getLabelForRecord,
  filterTypes,
}
