import budgets from './budgets'
import memberships from './memberships'
import expenses from './expenses'
import organisations from './organisations'
import providers from './providers'
import expenseCategories from './expenseCategory'

const resources = { budgets, memberships, expenses, organisations, providers, expenseCategories }

export const getResourceByName = resourceName => resources[resourceName]

export default resources
