import React from 'react'
import get from 'lodash/get'
import { NumberField } from 'react-admin'
import { LOCALE_DEFAULT } from '../config/locale'

const UMPriceField = ({ record, parse, ...props }) => {
  let parsedRecord = record
  if (record && parse) {
    parsedRecord = {
      ...record,
      [props.source]: parse(get(record, props.source)),
    }
  }
  return (
    <NumberField
      locales={LOCALE_DEFAULT}
      options={{ style: 'currency', currency: 'EUR' }}
      {...props}
      record={parsedRecord}
    />
  )
}

UMPriceField.defaultProps = {
  addLabel: true,
}

export default UMPriceField
