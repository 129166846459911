import gql from 'graphql-tag'
import buildGraphQLProvider from 'ra-data-graphql'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { getResourceByName } from './resources'
import { capitalizeString, singularizeString } from '../utils/strings'

const buildQuery = introspectionResults => (fetchType, resourceName, params) => {
  const resource = getResourceByName(resourceName)
  if (!resource) {
    return null
  }
  const query = resource.buildQuery(fetchType, resourceName, params)
  if (query) {
    return query
  }

  const collectionKey = resourceName
  const itemKey = singularizeString(resourceName)

  const buildQueryParts = params => {
    // Filters
    const combinedArguments = { ...params.filter }
    const combinedArgumentTypes = { page: 'Int', perPage: 'Int', sort: 'String' }
    Object.keys(combinedArguments).forEach(key => {
      combinedArgumentTypes[key] = resource.filterTypes[key]
    })

    // Target - used for reference
    if (params.target !== undefined) {
      combinedArguments[params.target] = params.id
      combinedArgumentTypes[params.target] = 'ID'
    }

    // Ids - used for GET_MANY
    if (params.ids !== undefined) {
      combinedArguments['ids'] = params.ids
      combinedArgumentTypes['ids'] = '[ID]!'
    }

    // Pagination
    if ('pagination' in params) {
      combinedArguments['page'] = params.pagination.page
      combinedArguments['perPage'] = params.pagination.perPage
    }

    // Sort
    if ('sort' in params) {
      const directionPrefix = params.sort.order === 'DESC' ? '-' : ''
      combinedArguments['sort'] = directionPrefix + params.sort.field
    }

    let variablePart = '',
      argumentPart = ''

    if (Object.keys(combinedArguments).length > 0) {
      variablePart = `(${Object.keys(combinedArguments)
        .map(key => `$${key}: ${combinedArgumentTypes[key]}`)
        .join(',')})`
      argumentPart = `(${Object.keys(combinedArguments)
        .map(key => `${key}: $${key}`)
        .join(',')})`
    }

    return { variablePart, argumentPart, variables: combinedArguments }
  }

  switch (fetchType) {
    case 'GET_LIST':
    case 'GET_MANY':
    case 'GET_MANY_REFERENCE': {
      const { variablePart, argumentPart, variables } = buildQueryParts(params)
      const query = `query ${collectionKey}${variablePart} {
          ${collectionKey}${argumentPart} {
            items {
              ${resource.fieldsPartial}
            }
            count
          }
        }`
      // console.log("query", query)
      // console.log("vars", variables)
      return {
        query: gql(query),
        variables,
        parseResponse: response => {
          const data = response.data[collectionKey]
          return { data: data.items, total: data.count }
        },
      }
    }
    case 'GET_ONE': {
      return {
        query: gql`query ${itemKey}($id: ID!) {
          ${itemKey}(id: $id) {
            ${resource.fieldsPartial}
          }
        }`,
        variables: {
          id: params.id,
        },
        parseResponse: response => {
          const data = response.data[itemKey]
          return { data }
        },
      }
    }
    case 'CREATE': {
      const createKey = `create${capitalizeString(itemKey)}`
      return {
        query: gql`mutation ${createKey}($input: ${capitalizeString(createKey)}Input!) {
          ${createKey}(input: $input) {
            success
            ${itemKey} {
              ${resource.fieldsPartial}
            }
          }
        }`,
        variables: {
          input: resource.buildMutationInput(params.data),
        },
        parseResponse: response => {
          const data = response.data[createKey][itemKey]
          return { data }
        },
      }
    }
    case 'UPDATE': {
      const updateKey = `update${capitalizeString(itemKey)}`
      return {
        query: gql`mutation ${updateKey}($id: ID!, $input: ${capitalizeString(updateKey)}Input!) {
          ${updateKey}(${itemKey}Id: $id, input: $input) {
            success
            ${itemKey} {
              ${resource.fieldsPartial}
            }
          }
        }`,
        variables: {
          input: resource.buildMutationInput(params.data),
          id: params.id,
        },
        parseResponse: response => {
          const data = response.data[updateKey][itemKey]
          return { data }
        },
      }
    }
    default:
      return null
  }
}

export default {
  configure: authProvider => {
    const httpLink = createHttpLink({ uri: 'https://umbrella-api-staging-vkuc2kf6dq-ew.a.run.app/graphql/' })
    const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      const user = await authProvider('AUTH_GETCURRENT')
      const token = user ? await user.getIdToken() : null

      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        }
      }

      return { headers }
    })

    return buildGraphQLProvider({
      clientOptions: { link: authLink.concat(httpLink) },
      buildQuery,
    })
  },
}
