import React from 'react'
import { List, TextField, ReferenceField, FunctionField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

export default props => (
  <List actions={null} bulkActions={false} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="category.name" label="Category" />
      <TextField source="provider.name" label="Provider" />
      <ReferenceField
        source="budget.membership.id"
        reference="memberships"
        linkType="show"
        label="User"
        sortBy="user.lastName"
      >
        <FunctionField render={record => `${record.user.firstName} ${record.user.lastName}`} />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="type" />
      <TextField source="amount" />
      <UMDateField source="startedOn" />
      <UMDateField source="endedOn" />
      <UMDateField source="expensedOn" />
    </UMDatagrid>
  </List>
)
