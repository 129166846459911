import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import DomainIcon from '@material-ui/icons/Domain'
import PeopleIcon from '@material-ui/icons/People'
import DataProvider from './api/DataProvider'
import AuthProvider from './api/AuthProvider'
import OrganisationList from './layouts/organisations/list'
import OrganisationShow from './layouts/organisations/show'
import MembershipList from './layouts/memberships/list'
import MembershipShow from './layouts/memberships/show'
import BudgetShow from './layouts/budgets/show'
import ExpenseList from './layouts/expenses/list'
import ExpenseCreate from './layouts/expenses/create'
import ExpenseEdit from './layouts/expenses/edit'

const fireBaseConfig = {
  apiKey: 'AIzaSyCYOWpSpnCCMRJJuyZNM8bOHXLw79mGLFE',
  authDomain: 'mymove-221113.firebaseapp.com',
  databaseURL: 'https://mymove-221113.firebaseio.com',
  projectId: 'mymove-221113',
  storageBucket: 'mymove-221113.appspot.com',
  messagingSenderId: '162025601799',
  appId: '1:162025601799:web:feb56f35473adbb7',
}

class App extends Component {
  state = {
    dataProvider: null,
  }

  componentDidMount() {
    AuthProvider.configure(fireBaseConfig).then(authProvider => {
      DataProvider.configure(authProvider).then(dataProvider => this.setState({ dataProvider, authProvider }))
    })
  }

  render() {
    const { dataProvider, authProvider } = this.state

    if (!dataProvider || !authProvider) {
      return null
    }

    return (
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="organisations" icon={DomainIcon} list={OrganisationList} show={OrganisationShow} />
        <Resource name="memberships" icon={PeopleIcon} list={MembershipList} show={MembershipShow} />
        <Resource name="budgets" show={BudgetShow} />
        <Resource name="expenses" list={ExpenseList} create={ExpenseCreate} edit={ExpenseEdit} />
        <Resource name="providers" />
        <Resource name="expenseCategories" />
      </Admin>
    )
  }
}

export default App
