import React from 'react'
import { List, TextField, ReferenceField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

export default props => (
  <List actions={null} bulkActions={false} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="user.lastName" label="Last name" />
      <TextField source="user.firstName" label="First name" />
      <ReferenceField
        source="organisation.id"
        reference="organisations"
        linkType="show"
        label="Organisation"
        sortBy="organisation.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <UMDateField source="startedOn" />
      <UMDateField source="endedOn" />
    </UMDatagrid>
  </List>
)
