import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { linkToRecord } from 'ra-core'

import { getResourceByName } from '../api/resources'

const styles = theme => ({
  root: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primary: {
    color: theme.palette.primary.main,
  },
})

const Link = ({ children, classes, className, variant, ...props }) => (
  <a
    className={classNames(
      classes.root,
      {
        [classes.primary]: variant === 'primary',
      },
      className,
    )}
    {...props}
  >
    {children}
  </a>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary']),
}

const StyledLink = withStyles(styles)(Link)

const getBreadcrumb = (state, resourceName, recordId, forceLabel) => {
  const resource = getResourceByName(resourceName)
  if (!resource) {
    return null
  }
  if (!recordId) {
    return {
      key: resourceName,
      label: forceLabel || resource.getLabel(state),
      link: '/#/' + resourceName,
    }
  }
  const record = resource.getRecordById(state, recordId)
  return {
    key: `${resourceName}/${recordId}`,
    label: forceLabel || (record ? resource.getLabelForRecord(record) : '…'),
    link: `${linkToRecord('/#/' + resourceName, recordId)}/show`,
  }
}

const getBreadcrumbs = (state, currentResourceName, currentRecord, currentLabel) => {
  const currentResource = getResourceByName(currentResourceName)
  let breadcrumbs = []
  if (currentResource && currentRecord) {
    let resource = currentResource
    let record = currentRecord
    let recordId = currentRecord.id
    while (resource) {
      breadcrumbs.push(getBreadcrumb(state, resource.getName(), recordId, currentLabel))
      let parentResource
      let parentRecordId
      const parentResourceName = resource.getParentResourceName()
      const parentRecordIdKey = resource.getParentResourceIdKey()
      record = record || resource.getRecordById(state, recordId)
      if (record && parentResourceName && parentRecordIdKey) {
        parentResource = getResourceByName(parentResourceName)
        parentRecordId = get(record, parentRecordIdKey)
      }
      if (!parentResource) {
        breadcrumbs.push(getBreadcrumb(state, resource.getName()))
      }
      resource = parentResource
      recordId = parentRecordId
      record = null
      currentLabel = null
    }
  }

  const duplicates = new Set()
  breadcrumbs = breadcrumbs.reverse().filter(breadcrumb => {
    if (!breadcrumb || duplicates.has(breadcrumb.key)) {
      return false
    }
    duplicates.add(breadcrumb.key)
    return true
  })
  return breadcrumbs
}

const Breadcrumbs = ({ breadcrumbs = [] }) => {
  const parents = [...breadcrumbs]
  const current = parents.pop()
  return (
    <>
      {parents.filter(Boolean).map(parent => (
        <span key={parent.key}>
          <StyledLink href={parent.link}>{parent.label}</StyledLink>
          <ChevronRightIcon style={{ verticalAlign: 'bottom', opacity: 0.5 }} />
        </span>
      ))}
      {current ? <span key={current.key}>{current.label}</span> : null}
    </>
  )
}

const UMBreadcrumbs = connect((state, ownProps) => ({
  breadcrumbs: getBreadcrumbs(state, ownProps.resourceName, ownProps.record, ownProps.currentLabel),
}))(Breadcrumbs)

export default UMBreadcrumbs
