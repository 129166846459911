import { LOCALE_DEFAULT } from '../config/locale'

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString('i')
  } catch (error) {
    return error instanceof RangeError
  }
  return false
})()

export const formatDate = (value, locales = LOCALE_DEFAULT, options, showTime = false) => {
  const date = value instanceof Date ? value : new Date(value)
  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
    ? date.toLocaleDateString(locales, options)
    : date.toLocaleDateString()
  return dateString
}
