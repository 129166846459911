import get from 'lodash/get'

const fieldsPartial = `
  id
  organisation {
    id
    name
  }
  user {
    id
    firstName
    lastName
  }
  startedOn
  endedOn
  createdOn
  updatedOn
  currentBudget {
    id
    total
    used
    remaining
  }
`

const filterTypes = {
  ids: '[ID]',
  organisationId: 'ID',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getParentResourceName = () => 'organisations'

const getParentResourceIdKey = () => 'organisation.id'

const getName = () => 'memberships'

const getLabel = () => 'Memberships'

const getRecordById = (state, id) => get(state, `admin.resources.memberships.data.${id}`, null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.user.firstName} ${record.user.lastName}`
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResourceName,
  getParentResourceIdKey,
  getName,
  getLabel,
  getRecordById,
  getLabelForRecord,
  filterTypes,
}
