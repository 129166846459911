import get from 'lodash/get'

const fieldsPartial = `
  id
  name
  summary {
    employeeCount
    totalBudget
    remainingBudget
    usedBudget
  }
  createdOn
  updatedOn
`

const filterTypes = {
  ids: '[ID]',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getParentResourceName = () => null

const getParentResourceIdKey = () => null

const getName = () => 'organisations'

const getLabel = () => 'Organisations'

const getRecordById = (state, id) => get(state, `admin.resources.organisations.data.${id}`, null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResourceName,
  getParentResourceIdKey,
  getName,
  getLabel,
  getRecordById,
  getLabelForRecord,
  filterTypes,
}
