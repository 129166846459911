import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ReferenceManyField,
  CardActions,
  Link,
  Button,
  Pagination,
} from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMDateField from '../../components/UMDateField'
import AddIcon from '@material-ui/icons/Add'
import budgets from '../../api/resources/budgets'
import UMFullNameField from '../../components/UMFullNameField'

const ShowActions = props => {
  const budgetRecord = props.data
  return (
    <CardActions>
      {budgetRecord && (
        <Button
          component={Link}
          to={{
            pathname: '/expenses/create',
            state: { record: { budget: budgetRecord } },
          }}
          label="New expense"
        >
          <AddIcon />
        </Button>
      )}
    </CardActions>
  )
}

export default props => (
  <Show title={<UMBreadcrumbs resourceName={budgets.getName()} />} actions={<ShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField source="membership.id" reference="memberships" label="Member" linkType="show">
        <UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />
      </ReferenceField>
      <UMDateField source="startsOn" />
      <UMDateField source="endsOn" />
      <UMPriceField source="total" parse={v => (v ? v / 100 : 0)} />
      <UMPriceField source="used" parse={v => (v ? v / 100 : 0)} />
      <UMPriceField source="remaining" parse={v => (v ? v / 100 : 0)} />
      <ReferenceManyField
        label="Expenses"
        reference="expenses"
        target="budgetId"
        sort={{ field: 'expensedOn', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <UMDatagrid rowClick="edit" emptyText="No expenses">
          <TextField source="category.name" label="Category" />
          <TextField source="provider.name" label="Provider" />
          <TextField source="title" />
          <TextField source="type" />
          <UMPriceField source="amount" parse={v => (v ? v / 100 : 0)} />
          <UMDateField source="startedOn" />
          <UMDateField source="endedOn" />
          <UMDateField source="expensedOn" />
        </UMDatagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
