import get from 'lodash/get'
import { formatDate } from '../../utils/dates'

const fieldsPartial = `
  id
  budget {
    id
    membership {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
  title
  type
  category {
    id
    name
  }
  provider {
    id
    name
  }
  amount
  startedOn
  endedOn
  expensedOn
`

const paramsTypesPartial = `
  $id: ID!,
  $budgetId: ID!,
  $title: String!,
  $type: ExpenseType!,
  $categoryId: ID!,
  $providerId: ID!,
  $amount: Int!,
  $startedOn: DateTime,
  $endedOn: DateTime!,
  $expensedOn: DateTime!
  $providerReference: String,
  $justification: String,
`

const paramsMapPartial = `
  id: $id,
  budgetId: $budgetId,
  title: $title,
  type: $type,
  categoryId: $category,
  providerId: $provider,
  amount: $amount,
  startedOn: $startedOn,
  endedOn: $endedOn,
  expensedOn: $expensedOn,
  providerReference: $providerReference,
  justification: $justification
`

const filterTypes = {
  ids: '[ID]',
  budgetId: 'ID',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const buildMutationInput = params => {
  return Object.keys(params).reduce((accumulator, currentKey) => {
    switch (currentKey) {
      case 'budget':
      case 'category':
      case 'provider':
        accumulator = { ...accumulator, [currentKey + 'Id']: params[currentKey]['id'] }
        break
      case 'id':
      case '__typename':
        break
      default:
        accumulator = { ...accumulator, [currentKey]: params[currentKey] }
    }
    return accumulator
  }, {})
}

const getParentResourceName = () => 'budgets'

const getParentResourceIdKey = () => 'budget.id'

const getName = () => 'expenses'

const getLabel = () => 'Expenses'

const getRecordById = (state, id) => get(state, `admin.resources.expenses.data.${id}`, null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.title} on ${formatDate(record.expensedOn)}`
}
export default {
  fieldsPartial,
  paramsTypesPartial,
  paramsMapPartial,
  buildQuery,
  buildMutationInput,
  getParentResourceName,
  getParentResourceIdKey,
  getName,
  getLabel,
  getRecordById,
  getLabelForRecord,
  filterTypes,
}
