import React from 'react'
import { Create, SimpleForm, TextInput, DateInput, NumberInput, ReferenceInput, SelectInput } from 'react-admin'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import get from 'lodash.get'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import budgets from '../../api/resources/budgets'
import expenses from '../../api/resources/expenses'

const redirect = (basePath, id, data) => {
  return `/budgets/${data['budget']['id']}/show`
}

const ExpenseCreate = ({ parentRecord, dispatch, ...props }) => {
  if (!parentRecord) {
    dispatch(push('/'))
    return null
  }

  return (
    <Create title={<UMBreadcrumbs resourceName={expenses.getName()} currentLabel="New expense" />} {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="title" />
        <SelectInput
          source="type"
          defaultValue="PRIVATE"
          choices={[{ id: 'PRIVATE', name: 'Private' }, { id: 'PRO', name: 'Pro' }]}
        />
        <ReferenceInput label="Category" source="categoryId" reference="expenseCategories">
          <SelectInput optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>

        <ReferenceInput label="Provider" source="providerId" reference="providers">
          <SelectInput optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>

        <NumberInput source="amount" defaultValue={0} label="Amount (in cents)" />
        <DateInput source="startedOn" defaultValue={new Date()} />
        <DateInput source="endedOn" defaultValue={new Date()} />
        <DateInput source="expensedOn" defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  )
}

export default connect((state, ownProps) => {
  const record = get(ownProps, 'location.state.record')
  const parentRecord = record ? budgets.getRecordById(state, record.budget.id) : null
  return {
    parentRecord,
  }
})(ExpenseCreate)
