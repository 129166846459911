import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceField, ReferenceManyField } from 'react-admin'

import UMDatagrid from '../../components/UMDatagrid'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMDateField from '../../components/UMDateField'
import memberships from '../../api/resources/memberships'

export default props => (
  <Show title={<UMBreadcrumbs resourceName={memberships.getName()} />} actions={null} {...props}>
    <SimpleShowLayout>
      <TextField source="user.lastName" label="Last name" />
      <TextField source="user.firstName" label="First name" />
      <ReferenceField source="organisation.id" reference="organisations" linkType="show" label="Organisation">
        <TextField source="name" label="Organisation" />
      </ReferenceField>
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
      <ReferenceManyField
        label="Budgets"
        reference="budgets"
        target="membershipId"
        sort={{ field: 'startsOn', order: 'DESC' }}
      >
        <UMDatagrid rowClick="show" emptyText="No budgets">
          <UMDateField source="startsOn" />
          <UMDateField source="endsOn" />
          <UMPriceField source="total" parse={v => (v ? v / 100 : 0)} />
          <UMPriceField source="used" parse={v => (v ? v / 100 : 0)} />
          <UMPriceField source="remaining" parse={v => (v ? v / 100 : 0)} />
        </UMDatagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
