import React from 'react'
import { Datagrid } from 'react-admin'
import Typography from '@material-ui/core/Typography'

const UMDatagrid = ({ emptyText = 'No results', isFullList = false, ...props }) => {
  if (!props.loadedOnce) {
    return (
      <Typography variant="body1" style={isFullList ? { marginTop: 16, marginLeft: 12 } : null}>
        Loading…
      </Typography>
    )
  }
  if (props.total === 0) {
    return (
      <Typography variant="body1" style={isFullList ? { marginTop: 16, marginLeft: 12 } : null}>
        {emptyText}
      </Typography>
    )
  }
  return <Datagrid {...props} />
}

export default UMDatagrid
