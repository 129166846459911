import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceManyField, FunctionField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMDateField from '../../components/UMDateField'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import organisations from '../../api/resources/organisations'
import { RadialBarChart, RadialBar } from 'recharts'
import UMPriceField from '../../components/UMPriceField'
import { LOCALE_DEFAULT } from '../../config/locale'

const keyFigureStyles = {
  keyFigure: {
    margin: '10px 20px 10px 0',
  },
  chart: {
    float: 'left',
    height: 100,
    marginTop: -100,
  },
  smallChart: {
    float: 'left',
    marginTop: -20,
    marginBottom: -50,
  },
}

const RawKeyFigures = ({ record, classes }) => {
  const data = [
    {
      uv: record.summary.totalBudget,
      fill: '#eeeeee',
    },
    {
      uv: record.summary.usedBudget,
      fill: '#8884d8',
    },
  ]

  return (
    <Grid container>
      <Card className={classes.keyFigure}>
        <CardContent>
          <Typography variant="display3" color="textPrimary">
            {record.summary.employeeCount}
          </Typography>
          <div>Employees</div>
        </CardContent>
      </Card>
      <Card className={classes.keyFigure}>
        <CardContent>
          <Typography variant="display3" color="textPrimary">
            {(record.summary.remainingBudget / 100).toLocaleString(LOCALE_DEFAULT)}
          </Typography>
          <Grid container>
            <div>
              Budget left{' '}
              <Typography variant="caption" color="textPrimary">
                ({(record.summary.usedBudget / 100).toLocaleString(LOCALE_DEFAULT)} used of{' '}
                {(record.summary.totalBudget / 100).toLocaleString(LOCALE_DEFAULT)})
              </Typography>
            </div>
            <div className={classes.chart}>
              <RadialBarChart
                width={200}
                height={300}
                innerRadius="10%"
                outerRadius="80%"
                data={data}
                startAngle={180}
                endAngle={0}
                barCategoryGap={1}
              >
                <RadialBar background clockWise={true} dataKey="uv" cx={'10%'} />
              </RadialBarChart>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const KeyFigures = withStyles(keyFigureStyles)(RawKeyFigures)

const Component = props => (
  <Show title={<UMBreadcrumbs resourceName={organisations.getName()} />} actions={null} {...props}>
    <SimpleShowLayout>
      <KeyFigures />

      <TextField source="name" />

      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />

      <ReferenceManyField
        label="Members"
        reference="memberships"
        target="organisationId"
        sort={{ field: 'user.lastName', order: 'ASC' }}
      >
        <UMDatagrid rowClick="show" emptyText="No members">
          <TextField source="user.lastName" label="Last name" />
          <TextField source="user.firstName" label="First name" />
          <UMDateField source="startedOn" />
          <UMDateField source="endedOn" />
          <UMPriceField label="Current budget total" source="currentBudget.total" parse={v => (v ? v / 100 : 0)} />>
          <UMPriceField label="Current budget used" source="currentBudget.used" parse={v => (v ? v / 100 : 0)} />>
          <UMPriceField
            label="Current budget remaining"
            source="currentBudget.remaining"
            parse={v => (v ? v / 100 : 0)}
          />
          <FunctionField
            label="Budget status"
            render={record => {
              console.log(record)

              if (!record.currentBudget) {
                return null
              }

              const data = [
                {
                  uv: record.currentBudget.total,
                  fill: '#eeeeee',
                },
                {
                  uv: record.currentBudget.used,
                  fill: '#8884d8',
                },
              ]

              return (
                <div className={props.classes.smallChart}>
                  <RadialBarChart
                    width={100}
                    height={100}
                    innerRadius="10%"
                    outerRadius="80%"
                    data={data}
                    startAngle={180}
                    endAngle={0}
                    barCategoryGap={1}
                  >
                    <RadialBar background clockWise={true} dataKey="uv" cx={'10%'} />
                  </RadialBarChart>
                </div>
              )
            }}
          />
        </UMDatagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default withStyles(keyFigureStyles)(Component)
